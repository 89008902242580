<div
  class="global-dialog ow-dialog window-a primary no-transparent"
  [class.padding-logo]="task.task_definition.partner_logo"
  [ngClass]="task?.taskClasses"
  [class.rank-desc]="task.task_definition.rank_description">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button *ngIf="data.backButton"></m-ui-back-button>

    <!--  RANKING BAR-->
    <div *ngIf="task?.rank_is_visible" class="back-bar-custom level1">
      <button matTooltip="Rankingi" class="square small primary margin" matTooltipPosition="above" matTooltipShowDelay="300" (click)="openRank(task.rank_edition_id)">
        <img [src]="assetsService.assetsData['/ui/ranking_2_hud.png'].path" />
      </button>
    </div>

  <!--  PDF BAR-->
  <div class="back-bar-custom level2" *ngIf="task.pdf_url">
    <button matTooltip="PDF" class="square small primary margin" matTooltipPosition="above" matTooltipShowDelay="300" (click)="openPdf()">
      <img [src]="assetsService.assetsData['/ui/pdf.png'].path" />
    </button>
  </div>

  <!-- NAVIGATION CENTER -->
  <ng-container *ngIf="notEmptyCardTasks.length > 1">
    <m-ui-navigation-center (prevClicked)="prev()" (nextClicked)="next()"></m-ui-navigation-center>
  </ng-container>

  <ng-container *ngIf="task">
    <!-- LAST IMPORT DATE -->
    <div class="last-import-date">
      <p>Wyniki z dnia: {{ task.last_import_date ? task.last_import_date : "b/d" }}</p>
    </div>

    <!-- LAST IMPORT DATE -->
    <div *ngIf="task.task_type !== TASK_TYPE.DAILY" class="date-range">
      Zadanie obowiązuje od {{ task.task_start_date | date: "dd-MM" }} do {{ task.task_end_date | date: "dd-MM-yyyy" }}
    </div>

    <!-- TITLE -->
    <div class="title">
      {{ task.task_definition.name }}
    </div>

    <!-- DESCRIPTION -->
    <div
      class="description margin-bottom"
      [ngClass]="{'extended': task.card_template === 3}"
      [innerText]="task.description"
      owEllipsis
      [isDisabledClick]="true"
      (click)="openDescriptionDialog(task.description)"></div>

    <!-- PROGRES BAR -->
    <ng-container *ngIf="task.progress" [ngTemplateOutlet]="progressTpl"></ng-container>

    <!-- CARD TEMPLATE S1S2-->
    <ng-container *ngIf="task.card_template === CARD_TEMPLATE.S1" [ngTemplateOutlet]="s1"></ng-container>

    <ng-container *ngIf="task.card_template === CARD_TEMPLATE.S2" [ngTemplateOutlet]="s2"></ng-container>

    <ng-container *ngIf="task.card_template === CARD_TEMPLATE.S3" [ngTemplateOutlet]="s3"></ng-container>
  </ng-container>
</div>

<!-- VALUE TYPE-->
<ng-template #valueTypeTpl let-value="value">
  <ng-container [ngSwitch]="task.task_definition.value_type">
    <ng-container *ngSwitchCase="2"> {{ Math.floor(value*100) }}{{ '%' }} </ng-container>
    <ng-container *ngSwitchCase="1">{{ Math.floor(value) }}{{task.task_definition.string_format +'.'}}</ng-container>
  </ng-container>
</ng-template>

<!-- PROGRESS TPL -->
<ng-template #progressTpl>
  <!-- PROGRESSBAR -->
  <div class="progress-title">
    {{ task.progress.title | replace: { ".": "," } }}
  </div>

  <div class="progress-bar-container">
    <div class="progress-container-outer">
      <div class="progress-container">
        <div class="fill custom-color" [ngClass]="task.progress.classes" [style.width]="task.progress.valuePercentage"></div>
      </div>
      <div class="value-progressbar">
        <p class="percentage">
          {{ task.progress.label }}
        </p>
      </div>
      <div class="pointer-container">
        <div
          *ngIf="task.progress.valuePercentage > 3 && task.progress.valuePercentage < 97"
          class="pointer"
          [style.width]="task.progress.valuePercentage"></div>
      </div>
    </div>

    <div *ngIf="task.progress.from as value" class="target-from">
      {{ value | replace: { ".": "," } }}
    </div>
    <div *ngIf="task.progress.to as value" class="target-to">
      {{ value | replace: { ".": "," } }}
    </div>
  </div>
</ng-template>

<!-- S1TPL -->
<ng-template #s1>
  <ng-container *ngIf="task.target">
  <div class="scopes-v1">
    <div *ngFor="let scope of task.scopes" class="scope-v1" [class.restrict]="task.scopes.length > 5">
      <div class="target-v1">>{{ scope.target | percent: "0.0-2" }}</div>

      <div class="prizes" [class.expand-prizes]="expandPrizes">
        <div class="prize">
          <m-ui-currency
            *ngFor="let currency of scope.currency_prizes"
            [item]="currency"
            [lack]="false"
            [customIconSize]="'small'"
            [stockView]="STOCK_VIEW.A"
            [customClassStock]="'business-card s1s2'"></m-ui-currency>
        </div>
      </div>
    </div>
  </div>


    <div class="table-container">
      <table>
        <tbody>
          <tr>
            <td>Cel:</td>
            <td>
              <ng-container [ngTemplateOutlet]="valueTypeTpl" [ngTemplateOutletContext]="{ value: task.target }"></ng-container>
            </td>
          </tr>
          <tr *ngIf="!(task.target === 1 && task.task_definition.string_format === '%')">
            <td>Wykonanie:</td>
            <td>
              <ng-container [ngTemplateOutlet]="valueTypeTpl" [ngTemplateOutletContext]="{ value: task.performance || 0 }"></ng-container>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="line"></div>

      <table>
        <tbody>
          <tr>
            <td>Procent realizacji:</td>
            <td>
              {{ task.progress.valuePercentage | replace: { ".": "," } }}
            </td>
          </tr>
          <tr>
            <td>Wypłacona nagroda:</td>
            <td>
              <m-ui-currency
                *ngFor="let prize of task.paid_scope_prizes"
                [item]="prize"
                [lack]="false"
                [stockView]="STOCK_VIEW.J"
                [customIconSize]="'small'"
                [customClassStock]="'business-card s1s2'"></m-ui-currency>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</ng-template>

<!-- S2 TPL -->
<ng-template #s2>
  <div class="parameters">
    <div class="prizes-paid-container" *ngFor="let prize of task.progressive_currency_prizes">
      <ng-container>
        <div class="description-prize">
          <ng-container [ngSwitch]="task.task_definition.string_format">
            <p class="description-prize-text" *ngSwitchCase="'ptk'">Nagroda za każdy punkt:</p>
            <p class="description-prize-text" *ngSwitchCase="'pkt'">Nagroda za każdy punkt:</p>
            <p class="description-prize-text" *ngSwitchCase="'szt'">Nagroda za każdą sztukę:</p>
            <p class="description-prize-text" *ngSwitchCase="'%'">Nagroda za każdy procent:</p>
            <p class="description-prize-text" *ngSwitchDefault>Nagroda za każdą sztukę:</p>
          </ng-container>
          <p class="description-prize-value">{{ prize.unit_amount }}</p>
          <m-ui-currency [item]="prize" [hideValue]="true" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
        </div>
        <div class="prize-paid">
          <p class="prize-paid-text">Wypłacone nagrody:</p>
          <ng-container *ngFor="let toDisplayPrize of task.prizes_paid">
            <ng-container *ngIf="toDisplayPrize.currency_id === prize.currency_id">
              <p class="prize-paid-value">{{ toDisplayPrize.amount ?? 0}}</p>
              <m-ui-currency [item]="prize" [hideValue]="true" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!task.prizes_paid.length">
            <p class="prize-paid-value">{{ 0 }}</p>
            <m-ui-currency [item]="prize" [hideValue]="true" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <!--      // zmien format -->
    <div class="minimum" *ngIf="task.progressive_currency_prizes[0].min_realization_scope">
      <p class="minimum-text">Minimum:</p>
      <p class="minimum-value" *ngIf="task.task_definition.value_type === 2">
        {{ (+task.progressive_currency_prizes[0].min_realization_scope * 100).toFixed() + "%" }}
      </p>
      <p class="minimum-value" *ngIf="task.task_definition.value_type === 1">
        {{ (+task.progressive_currency_prizes[0].min_realization_scope).toFixed() }}
      </p>
    </div>
    <div class="maximum" *ngIf="task.progressive_currency_prizes[0].max_realization_scope">
      <p class="maximum-text">Maksimum:</p>
      <p class="minimum-value" *ngIf="task.task_definition.value_type === 2">
        {{ (+task.progressive_currency_prizes[0].max_realization_scope * 100).toFixed() + "%" }}
      </p>
      <p class="minimum-value" *ngIf="task.task_definition.value_type === 1">
        {{ (+task.progressive_currency_prizes[0].max_realization_scope).toFixed() }}
      </p>
    </div>
    <div class="realization">
      <p class="realization-text">Realizacja:</p>
      <p class="minimum-value" *ngIf="task.task_definition.value_type === 2">{{ Math.floor(parseFloat((+task.performance * 100).toFixed(8))) + "%" }}</p>
      <p class="minimum-value" *ngIf="task.task_definition.value_type === 1">
        {{ Math.floor(+task.performance) }}
      </p>
    </div>
    <ng-container *ngFor="let scope of task.scopes">
      <div class="bonus" *ngIf="scope.default">
        <ng-container *ngFor="let prize of scope.currency_prizes">
          <ng-container *ngIf="CURRENCY_ID_TO_DISPLAY.includes(prize?.currency_id)">
            <p class="bonus-text" *ngIf="task.task_definition.value_type === 2">
              Bonus za {{ (+task.scopes[0].target * 100).toFixed() + "%:" }}
            </p>
            <p class="bonus-text" *ngIf="task.task_definition.value_type === 1">
              Bonus za {{ Math.floor(+task.scopes[0].target) + " " + task.task_definition.string_format + "." }}
            </p>
            <p class="bonus-value">{{ prize.amount }}</p>
            <m-ui-currency [item]="prize" [hideValue]="true" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- S3 TPL -->
<ng-template #s3>
  <div class="parameters">
    <div class="prizes-paid-container" *ngFor="let prize of task.progressive_currency_prizes; let i = index">
      <ng-container>
        <div class="description-prize">
          <p class="description-prize-text">{{ i === 0 ? 'Maksymalne nagrody:' : ''}}</p>
          <p class="description-prize-value">{{ prize.max_prize_amount }}</p>
          <m-ui-currency [item]="prize" [hideValue]="true" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
        </div>
        <div class="prize-paid">
          <p class="prize-paid-text">{{ i === 0 ? 'Wypłacone nagrody:' : '' }}</p>
          <ng-container *ngFor="let toDisplayPrize of task.prizes_paid">
            <ng-container *ngIf="toDisplayPrize.currency_id === prize.currency_id">
              <p class="prize-paid-value">{{ toDisplayPrize.amount ?? 0}}</p>
              <m-ui-currency [item]="prize" [hideValue]="true" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!task.prizes_paid.length">
            <p class="prize-paid-value">{{ 0 }}</p>
            <m-ui-currency [item]="prize" [hideValue]="true" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div class="details" *ngIf="task?.details && task?.details != '0'">
      <p owEllipsis [innerText]="'Szczegóły: ' + task.details" [filterWordsInDialog]="['Szczegóły: ']"></p>
    </div>
  </div>
</ng-template>
